import React from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { isArray, isEmpty } from 'underscore';

import { ALL_BRANDS } from '../config/brands';
import { AUTO_APPLY_PROMO_CODE_VALIDITY } from '../config/constants';

// eslint-disable-next-line
import {
    STORAGEKEY_AUTH_TOKEN,
    STORAGEKEY_APP_LANGUAGE,
    STORAGEKEY_REFERRAL,
    APP_LANGUAGE_DEFAULT,
    APP_CURRENCY_SYMBOL,
    AUTH_API_URL,
    STORAGEKEY_TRADE_IN_DEVICE,
    STORAGEKEY_PAY_NOW_INFO,
    ROUTES,
    EXCLUDED_URLS
} from 'config/constants';
import axios from 'axios';

/**
 * Formats duration in seconds to days, hours, minutes, etc
 * @param  {Number} duration
 * @param  {Boolean} twoDigits
 * @param  {Array} only
 * @returns {Object}
 */
export function formatDuration(duration, twoDigits, only = []) {
    let seconds = duration;
    const out = {};

    if (!only.length || only.includes('days')) {
        out.days = {
            value: parseInt(seconds / (3600 * 24)),
            label: 'days'
        };
        seconds -= out.days.value * 3600 * 24;
    }

    if (!only.length || only.includes('hours')) {
        out.hours = {
            value: parseInt(seconds / 3600),
            label: 'hours'
        };
        seconds -= out.hours.value * 3600;
    }

    if (!only.length || only.includes('minutes')) {
        out.minutes = {
            value: parseInt(seconds / 60),
            label: 'minutes'
        };
        seconds -= out.minutes.value * 60;
    }

    if (!only.length || only.includes('seconds')) {
        out.seconds = {
            value: seconds,
            label: 'seconds'
        };
    }

    if (twoDigits) {
        for (const key in out) {
            out[key].value = fillZero(out[key].value);
        }
    }

    return out;
}

/**
 * Fix Floating Point Number Precision
 * @param {Number} num
 * @param {Number} precision
 * @param {Boolean} addZero
 * @returns {String}
 */
export function fixFloating(num, precision = 2, addZero = false) {
    let str = num.toString();

    let parts = str.split('.');

    let decimals = parts[1];

    if (!decimals) {
        decimals = '';
    }

    decimals = `${decimals.substr(0, precision + 1)}`;

    if (decimals.length > precision && parseInt(parts[1][precision]) > 5) {
        str = (
            parseFloat(`${parts[0]}.${decimals}`) +
            1 / 10 ** precision
        ).toString();

        parts = str.split('.');

        decimals = parts[1];

        if (!decimals) {
            decimals = '';
        }
    }

    decimals = decimals.substr(0, precision);

    if (addZero) {
        decimals = fillZero(decimals, 2, 'after');
    }

    return `${parts[0]}${decimals !== '' && (parseInt(decimals) || addZero) ? '.' + decimals : ''
        }`;
}

/**
 * Proxy all currency values (attach currency symbol, etc)
 * @param {Number} price
 * @param {Boolean} signed
 * @param {Boolean} addZero
 * @returns {String}
 */
export function currencify(price, signed, addZero) {
    if (price === 0) {
        return `${APP_CURRENCY_SYMBOL}${fixFloating(0, 2, addZero)}`;
    }

    if (price > 0) {
        return !signed
            ? `${APP_CURRENCY_SYMBOL}${fixFloating(price, 2, addZero)}`
            : `+${APP_CURRENCY_SYMBOL}${fixFloating(price, 2, addZero)}`;
    }

    if (price < 0) {
        return `-${APP_CURRENCY_SYMBOL}${fixFloating(
            Math.abs(price),
            2,
            addZero
        )}`;
    }
}

/**
 * if num length less than count, fill with zero
 * @param  {Number} num
 * @param  {Number} count
 * @param  {String} where
 * @returns {String}
 */
export function fillZero(num, count = 2, where) {
    const stringifiedNum = num.toString();
    const diff = count - stringifiedNum.length;

    if (diff > 0) {
        if (where === 'after') {
            return `${stringifiedNum}${'0'.repeat(diff)}`;
        } else {
            return `${'0'.repeat(diff)}${stringifiedNum}`;
        }
    }

    return stringifiedNum;
}

export function getLastPageUrlWithoutAuth() {
    let pageStack = JSON.parse(localStorage.getItem('locationHistoryStack'));
    const removeKeywords = [ROUTES.SIGN_IN, ROUTES.SIGN_UP, ROUTES.OTP_VERIFICATION, ROUTES.FORGOT_PASSWORD, ROUTES.SIGNUP];

    pageStack = pageStack.filter(url => {
        return !removeKeywords.some(keyword => url.includes(keyword));
    });

    if (pageStack && pageStack.length > 0) {
        return pageStack[pageStack.length - 1];
    }

    return ROUTES.HOME;
}

export const handleUnauthorizedError = () => {
    deleteAuthToken();
    window.location.href = '/';
};

/**
 *  token is optional so that it can be used in both cases
 * @param  {String} token
 * @returns {Function}
 */
export const createAxiosInstance = (token = '') => {
    const instance = axios.create({
        baseURL: AUTH_API_URL,
        headers: {
            Authorization: token ? `Bearer ${token}` : false
        }
    });

    instance.interceptors.response.use(
        response => response,
        error => {
            if (error.response && error.response.status === 401) {
                handleUnauthorizedError();
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

/**
 * Save login token
 * @param {String} token
 * @param {Boolean} isRemember
 */
export function setAuthToken(token, isRemember = true) {
    (isRemember ? localStorage : sessionStorage).setItem(
        STORAGEKEY_AUTH_TOKEN,
        token
    );

    Cookies.set(STORAGEKEY_AUTH_TOKEN, token, { path: '/', domain: '.cinch.sg', secure: true, sameSite: 'Strict' });
    // Cookies.set(STORAGEKEY_AUTH_TOKEN, token, { path: '/', secure: true, sameSite: 'Strict' });
}

/**
 * Get login token
 * @returns {String}
 */
export function getAuthToken() {
    let token = localStorage.getItem(STORAGEKEY_AUTH_TOKEN) || sessionStorage.getItem(STORAGEKEY_AUTH_TOKEN);

    if (token == null) {
        const cookieToken = getCookiesAuthToken();

        if (cookieToken) {
            setAuthToken(cookieToken);
            token = cookieToken;
        }
    }

    return (
        token
    );
}

/**
 * Get login token
 * @returns {String}
 */
export function getCookiesAuthToken() {
    return (
        Cookies.get(STORAGEKEY_AUTH_TOKEN)
    );
}

/**
 * Delete login token
 */
export function deleteAuthToken() {
    localStorage.removeItem(STORAGEKEY_AUTH_TOKEN);
    sessionStorage.removeItem(STORAGEKEY_AUTH_TOKEN);
    Cookies.remove(STORAGEKEY_AUTH_TOKEN, { path: '/', domain: '.cinch.sg' });
}

/**
 * Get app language
 * @returns {String}
 */
export function getAppLanguage() {
    return (
        localStorage.getItem(STORAGEKEY_APP_LANGUAGE) || APP_LANGUAGE_DEFAULT
    );
}

/**
 * Set app language
 * @param {String} language
 */
export function setAppLanguage(language) {
    localStorage.setItem(STORAGEKEY_APP_LANGUAGE, language);
}

/**
 * Listener for Fixed Content
 */
export function useFixedContent() {
    let prevScrollpos = window.pageYOffset;
    const isMobile = window.matchMedia('(max-width: 767px)').matches;

    window.onscroll = function() {
        const currentScrollPos = window.pageYOffset;
        const element = document.querySelectorAll('.--fixed-content')[0];

        if (element) {
            if (prevScrollpos > currentScrollPos) {
                element.style.opacity = '1';
                element.style.zIndex = '999';
                if (isMobile) element.classList.remove('--fixed-content__extended');
            } else if (currentScrollPos >= 0) {
                element.style.opacity = '1';
                element.style.zIndex = '999';
                if (isMobile) element.classList.remove('--fixed-content__extended');
            } else {
                element.style.opacity = '0';
                element.style.zIndex = '-1';
                if (isMobile) element.classList.remove('--fixed-content__extended');
            }
        }

        prevScrollpos = currentScrollPos;
    };
}

export const validateEmail = email => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

/**
 * Get category parent name based on child id
 * @param {Array} categories
 * @param {String} childId
 * @returns {String}
 */
export const getParentCategoryName = (categories, childId) => {
    const filteredCateg = categories.map((item) =>
        item.children.map((child) => {
            return child.id === childId ? item.name : null;
        })
    );

    const categName = filteredCateg.flat(1).join('');

    return categName;
};

export const AddTrailingZeros = num =>
    num.toLocaleString('en', { useGrouping: false, minimumFractionDigits: 2 });

export const returnImgOfColor = (id, medias) => {
    const findCOlorMediaPrimary = medias.find(e => e.color_id === id && e.primary);

    if (findCOlorMediaPrimary) {
        return findCOlorMediaPrimary.url;
    }

    const findCOlorMedia = medias.find(e => e.color_id === id);

    if (findCOlorMedia) {
        return findCOlorMedia.url;
    }

    return medias[0].url;
};

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function isProduction() {
    return window.location.hostname === 'cinch.sg';
}

export function createWebHistoryStack(location) {
    const currentPageUrl = window.location.href;
    const pageStack = JSON.parse(localStorage.getItem('routeHistoryStack')) || [];
    const locationsStack = JSON.parse(localStorage.getItem('locationHistoryStack')) || [];

    if (!locationsStack.includes(location.pathname) && !EXCLUDED_URLS.includes(location.pathname)) {
        // Add new items to the stacks
        locationsStack.push(location.pathname);
        pageStack.push(currentPageUrl);

        // Keep only the latest 100 items
        const maxStackSize = 100;

        if (locationsStack.length > maxStackSize) {
            locationsStack.splice(0, locationsStack.length - maxStackSize);
        }

        if (pageStack.length > maxStackSize) {
            pageStack.splice(0, pageStack.length - maxStackSize);
        }

        // Update localStorage
        localStorage.setItem('locationHistoryStack', JSON.stringify(locationsStack));
        localStorage.setItem('routeHistoryStack', JSON.stringify(pageStack));
    }
}

export function clearWebHistoryStack() {
    localStorage.removeItem('routeHistoryStack');
}

export function getLastPageUrl() {
    const pageStack = JSON.parse(localStorage.getItem('routeHistoryStack'));

    if (pageStack && pageStack.length >= 2) {
        return pageStack[pageStack.length - 2];
    }

    function getBaseUrl() {
        const protocol = window.location.protocol;
        const host = window.location.host;
        const baseUrl = `${protocol}//${host}`;

        return baseUrl;
    }

    return getBaseUrl();
}

export function setReferralParam(referralParam, isRemember = true) {
    (isRemember ? localStorage : sessionStorage).setItem(
        STORAGEKEY_REFERRAL,
        referralParam
    );
}

export function getReferralParam() {
    return (
        localStorage.getItem(STORAGEKEY_REFERRAL) ||
        sessionStorage.getItem(STORAGEKEY_REFERRAL)
    );
}

export function deleteReferralParam() {
    localStorage.removeItem(STORAGEKEY_REFERRAL);
    sessionStorage.removeItem(STORAGEKEY_REFERRAL);
}

export function deleteTradeInInfo() {
    localStorage.removeItem(STORAGEKEY_TRADE_IN_DEVICE);
    localStorage.removeItem(STORAGEKEY_PAY_NOW_INFO);
}

export function getNavigatorData() {
    var navigatorObj = {};

    for (var k in window.navigator) {
        navigatorObj[k] = window.navigator[k];
    }

    return navigatorObj;
}

export function getBrandTileSize(brand) {
    const result = ALL_BRANDS.find(b => b.name === brand.name);

    return result ? { class: result.class, left: result.left, top: result.top } : { class: 'brand__card__content__image--small', size: 'small' };
}

export const getDefaultRentalOptionOnProductItem = (product, selectedRental = {}) => {
    if (!isEmpty(selectedRental)) {
        return selectedRental?.duration_time;
    } else {
        if (product?.selectedRentalPeriod) {
            return product?.selectedRentalPeriod?.duration_time;
        } else {
            return product?.rental_period[product?.rental_period.length - 1]?.duration_time;
        }
    }
};

export const getDefaultColorOptionOnProductItem = (product, selectedColor = {}) => {
    if (!isEmpty(selectedColor)) {
        return selectedColor?.text || selectedColor?.name;
    } else {
        if (product?.medias?.length > 0) {
            const primaryColorMedia = product?.medias?.find(m => m?.primary) || product?.medias[0];

            if (primaryColorMedia) {
                const color = product?.colors?.find(color => color?.id === primaryColorMedia?.color_id);

                return color?.name;
            }
        }
    }

    return null;
};

export const getDefaultStorageOptionOnProductItem = (product, selectedStorage = {}, productAttributeValueId = '') => {
    if (!isEmpty(selectedStorage)) {
        return selectedStorage?.label;
    } else {
        if ((productAttributeValueId !== '' && productAttributeValueId !== undefined) && product?.attributes && isArray(product?.attributes)) {
            const attributes = product?.attributes?.filter(t => t.type === 'storage')[0];
            const selectedStorageLabel = attributes?.attribute_values.filter(s => s.id === productAttributeValueId)[0];

            return selectedStorageLabel?.value;
        } else {
            return product?.attributes?.filter(t => t.type === 'storage')[0]?.attribute_values[0]?.value;
        }
    }
};

export const getTheBrandNamePerGivenBrandId = (brandId) => {
    const brands = JSON.parse(localStorage.getItem('basicBrands'));

    return brands && brands.filter(b => b?.id === brandId)[0]?.name;
};

export const isAutoApplyCouponValid = () => {
    const cutOffTime = new Date(AUTO_APPLY_PROMO_CODE_VALIDITY).getTime();
    const currentTime = new Date().getTime();

    return currentTime < cutOffTime;
};
